import React from 'react';
//import Header from './component/tempinput/Header';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import tempinput from './component/tempinput/tempinput';
import templist from "./component/templist/templist";
import login from "./component/login/login";
import MemberTemp from "./component/membertemp/MemberTemp";

class App extends React.Component {


  render() {
    return (
      <div> 
        {/*<Header />*/}
        {/*<tempinput />*/}
         
        <BrowserRouter>
          
          <Switch>
            
            <Route path="/" exact component={login}/>  {/*ログイン画面*/}
            <Route path="/tempinput" exact component={tempinput} />  {/*体温入力画面*/}
            <Route path="/templist" exact component={templist} />  {/*体温一覧画面*/}
            <Route path="/membertemp" exact component={MemberTemp} />  {/*体温管理画面*/}

          </Switch>
        </BrowserRouter>
      </div>
      

    );
  }
}

export default App;
