import React, { Component } from 'react';
import axios from 'axios';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import './memberTemp.css';
import '../templist/tableStyle.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Header from '../common/Header';//ヘッダー部を読み込み
import KUC_Calendar from '../common/KUC_Calendar';//カレンダー部を読み込み
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';

class MemberTemp extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        memberList: [],
        groupList: [],
        GROUP : "",
        options : [],
        syain_id : this.props.location.state.syain_id,  //メニュー画面から受け取ったパラメータ値を利用する
        YEAR : new Date().getFullYear(),
        MONTH : ('0'+(new Date().getMonth()+1)).slice(-2),
        DAY : ('0'+new Date().getDate()).slice(-2),
        dayData: []
    };
    this.handleChangeGroup =  this.handleChangeGroup.bind(this);
    this.datechange = this.datechange.bind(this);
    }

  componentDidMount() { //画面起動時に検索処理実行
    this.getMemberList();
    this.getGroupCode();
  }
  
  getMemberList(){
      const url = 'https://0i44hkde75.execute-api.ap-northeast-1.amazonaws.com/dev/g-tempselect?SYAIN_ID=' + this.state.syain_id + '&GROUP='
                  + this.state.GROUP + '&DATE=' + this.state.YEAR + '-' + ('0' + this.state.MONTH).slice(-2) + '-' + ('0' + this.state.DAY).slice(-2);
      axios.get(url) //APIGateWay呼び出し
      .then (res => {
        const memberData = res.data.record;
        this.setState({memberList : memberData});
        if(this.state.GROUP == ""){//グループコードが無い場合自分のグループコードを初期値としてセット
          this.setState({
            GROUP : memberData[0].GROUP
          });
        }
      })
      .catch(() => {
        alert('通信に失敗しました');
        console.log(url)
      });  
  };
  
  symp_check(symp) {
    if(symp === "True"){
      return '有'
    }
    else {
      return '無'
    }
  }
  

	handleChangeGroup(event) {
	  this.setState({GROUP: event.target.value},() => {this.getMemberList();}
	  );
	  console.log(event.target.value);
	}
	
	getGroupCode() {
	  const url = "https://cggvqi0q1g.execute-api.ap-northeast-1.amazonaws.com/dev/getgroupcode?SYAIN_ID=" + this.state.syain_id;
	    axios.get(url)
	    .then (res => {
	      const groupdata = res.data.record;
	      this.setState({groupList : groupdata});
	    })
	}
  
  memberinfo(name,id){
    return(
      <div>
        <button className="linkstyle_btn" onClick={()=>{this.moveList(id)}}>{name}</button>
      </div>
  )
  }
  
  moveList(syainid){
    this.props.history.push({
    pathname: "/templist",
    state: {
            syain_id: this.state.syain_id,
            searchID: syainid
           }
  });
  }
  
  //コールバック関数
  //カレンダーで日付変更をするとこの関数が呼び出される
  datechange(year,month,day){
    this.setState({
      YEAR : year,
      MONTH : month,
      DAY : day
    },() => {this.getMemberList();}
    );
  }

	render () {
    return(
      <div>
        {/*ヘッダー・サイドメニュー*/}
        <div>
          <Header title='体温管理' parent_value={this.state.syain_id} />
        </div>

        <div id="searchArea">
        <div>{/*カレンダーコンポーネント　コールバック関数で日付変更時に検索するようにしている*/}
          <KUC_Calendar year={this.state.YEAR} month={this.state.MONTH} day={this.state.DAY} callback={this.datechange} />
        </div>
        {/*チャットボット埋め込み*}
          <iframe src='https://d32trjq49e1qir.cloudfront.net/index.html' height='500' width='250'/>*/}
          <script async src="https://d35zn3px9w9wn4.cloudfront.net/lex-web-ui-loader.min.js" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                  var loaderOpts = {
    baseUrl: 'https://d35zn3px9w9wn4.cloudfront.net/',
    shouldLoadMinDeps: true
  };
  var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
  var chatbotUiConfig = {
          /* Example of setting session attributes on parent page
          lex: {
            sessionAttributes: {
              userAgent: navigator.userAgent,
              QNAClientFilter: ''
            }
          }
          */
        };
  loader.load(chatbotUiConfig)
    .catch(function (error) { console.error(error); });
              `
            }}
          />
          <p></p>
          <FormControl>
            <InputLabel id="group-label">係</InputLabel>
            <Select
              labelId="group-label"
              id="group-select"
              value={this.state.GROUP}
              label="group"
              onChange={this.handleChangeGroup}
            >
              {this.state.groupList.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
          <TableContainer component={Paper}>
            <Table className="table" aria-label="simple table">
              <TableHead id="tableheader">
                <TableRow>
                  <TableCell className="thead lcell"></TableCell>
                  <TableCell className="thead">出勤前</TableCell>
                  <TableCell className="thead">帰宅後</TableCell>
                  <TableCell className="thead">息苦しさ</TableCell>
                  <TableCell className="thead">強いだるさ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="tablebody">
                {this.state.memberList.map((memberItem) => (
                  <TableRow key={memberItem.SYAIN_ID} className={memberItem.STYLE}>{/*Lambda側で体温・症状を判定して適用するスタイルのクラス名を返している*/}
                    <TableCell className="tbody lcell" component="th">                                     {/*"betemp"=37.5度以上,"besymp=症状有",notstyle=正常*/}         
                      {this.memberinfo(memberItem.NAME,memberItem.SYAIN_ID)}                                 {/*体温・症状共に異常がある場合"betempが返ってくる"*/}
                    </TableCell>
                    <TableCell className="tbody" style={{color:memberItem.TEMPS_COLOR}}>{memberItem.TEMP_SYUKKIN}</TableCell>{/*上記と同じでLambda側で判定し、文字色を返している*/}
                    <TableCell className="tbody" style={{color:memberItem.TEMPT_COLOR}}>{memberItem.TEMP_TAIKIN}</TableCell>
                    <TableCell className="tbody" style={{color:memberItem.SYMP1_COLOR}}>{this.symp_check(memberItem.SYMPTOM.symp1)}</TableCell>{/*symp1,2の値をチェックし、有か無に変換している*/}
                    <TableCell className="tbody" style={{color:memberItem.SYMP2_COLOR}}>{this.symp_check(memberItem.SYMPTOM.symp2)}</TableCell>{/*時間できたらループでテーブル作るようにできないか考える
                                                                                                                                            流動的に*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
	}
}    

export default MemberTemp;