import React from 'react';
import axios from 'axios';//API Gatewayを呼び出すメソッド
import {withRouter} from 'react-router';//子コンポーネント(Header)から他の画面にstateを送るために必要
import PropTypes from 'prop-types';//子コンポーネント(Header)から他の画面にstateを送るために必要
import {Grid,Card,CardHeader,CardContent,Divider,} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";// date-io
import {DatePicker,MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";// material-ui/pickers
import ja from "date-fns/locale/ja";
import KUC_CalendarStyle from './KUC_CalendarStyle.module.css'; //CSSをオブジェクト化して、適用したい場所だけを指定している
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const weekday = ['日', '月', '火', '水', '木', '金', '土'];


class KUC_Calendar extends React.Component {

    constructor(props) {
      super(props);
      
      //stateの中に入れた変数の値は共有される
      this.state = {
        date: new Date(),
        /*year:new Date().getFullYear(),
        month:('0'+(new Date().getMonth()+1)).slice(-2),
        day:('0'+new Date().getDate()).slice(-2),*/
        year:this.props.year,
        month:this.props.month,
        day:this.props.day,
        week:weekday[new Date().getDay()],
        //propsで親のコールバック関数を受け取っている
        callback : this.props.callback
      };
      
      this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange= (Calendar_date) =>{
      this.setState(state=>({
        date:Calendar_date,
        year:Calendar_date.getFullYear(),
        month:('0'+(Calendar_date.getMonth()+1)).slice(-2),
        day:('0'+Calendar_date.getDate()).slice(-2),
        }),()=>this.state.callback(this.state.year,this.state.month,this.state.day)
      );
      
    }
    
    //※「2021-09-29」の形で日付をDBに入力する場合は以下のコードを使う
    //+ this.state.year+ '-'+ this.state.month+ '-' +this.state.day
    
    //「2021/10/5 9:50:10」の形式でDBに登録する場合
    //'&DATE='+ this.state.date.toLocaleString()    
    
    //日付を昨日に変更
    handleBackDay(event) {
	  const yesterday = new Date(this.state.year, this.state.month-1, this.state.day);
	  yesterday.setDate(yesterday.getDate() - 1);
	  this.setState({
	    year: yesterday.getFullYear(),
	    month: ('0'+(yesterday.getMonth()+1)).slice(-2),
	    day: ('0'+yesterday.getDate()).slice(-2),
	    week:weekday[yesterday.getDay()]
	    },()=>this.state.callback(this.state.year,this.state.month,this.state.day)
	    );
  	}

    //日付を明日に変更
  	handleForwardDay(event) {
  	  const tomorrow = new Date(this.state.year, this.state.month-1, this.state.day);
  	  tomorrow.setDate(tomorrow.getDate() + 1);
  	  this.setState({
  	    year: tomorrow.getFullYear(),
  	    month: ('0'+(tomorrow.getMonth()+1)).slice(-2),
  	    day: ('0'+tomorrow.getDate()).slice(-2),
  	    week:weekday[tomorrow.getDay()]
  	  },()=>this.state.callback(this.state.year,this.state.month,this.state.day)
  	  );
  	}
    
  render() {
    
    return (
    <div>
      <Grid className={KUC_CalendarStyle.date}>
        <Grid className={KUC_CalendarStyle.Calendar}>
          <ArrowLeftIcon className={KUC_CalendarStyle.ArrowIcon} onClick={() => {this.handleBackDay();}}/>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja} >
            <DatePicker
              inputProps={{className: KUC_CalendarStyle.textfield}}
              margin="normal"
              id="Calendar"
              // format="yyyy年MM月dd日"
              format={this.state.year +'年'+ this.state.month +'月' + this.state.day + '日' + '　' + this.state.week + '曜日'}
              value={this.state.date}
              selected={this.Calendar_date}
              onChange={this.handleDateChange}
            />
          </MuiPickersUtilsProvider>
          <ArrowRightIcon className={KUC_CalendarStyle.ArrowIcon} onClick={() => {this.handleForwardDay();}}/>
        </Grid>
      </Grid>
    </div>
 );
  }


}
export default KUC_Calendar;