import React from 'react';
import Style from './stylesheet.module.css'; //CSSをオブジェクト化して、適用したい場所だけを指定している
import Button from '@material-ui/core/Button'; //Material-uiのボタンを読み込んでいる
import axios from 'axios';//API Gatewayを呼び出すメソッド
import Header from '../common/Header';//ヘッダー部を読み込み
import Modal from '../common/Modal';//ポップアップを読み込み
import KUC_Calendar from '../common/KUC_Calendar';//カレンダーコンポーネントを読み込み
//import Class_test from './Class_test';
//import Test from './Test';


//クラスコンポーネント
class App extends React.Component {

    constructor(props) {
    super(props);
    
    //stateの中に入れた変数の値は共有される
    this.state = {
      date: new Date(),
      year: this.props.location.state.year,
      month: this.props.location.state.month,
      day: this.props.location.state.day,
      syain_id: this.props.location.state.syain_id,//ログイン画面から社員IDを受け取る
      temp_syukkin:'',
      temp_taikin:'',
      symptom1:'',
      symptom2:'',
      healthList:[{"SYAIN_ID": "", "NAME": "", "DEPARTMENT": "", "SECTION": "", "GROUP": "", "record": [{"DATE": "", "SYMPTOM": {"symp1": "", "symp2": ""}, "TEMP_SYUKKIN": "", "TEMP_TAIKIN": ""}]}],
      isModalOpen: false,
    };
    
    this.syain_idhandleChange = this.syain_idhandleChange.bind(this);
    this.syukkinhandleChange = this.syukkinhandleChange.bind(this);
    this.taikinhandleChange = this.taikinhandleChange.bind(this);
    this.closeModal= this.closeModal.bind(this);
    this.datechange = this.datechange.bind(this);
    
    }

    componentWillMount() { //画面起動時にレコード取得処理実行
      this.templist();
    }
    
    syain_idhandleChange(e){
      this.setState({syain_id:e.target.value});
      
    } 
    

    syukkinhandleChange(e){
      this.setState({temp_syukkin:e.target.value});
      
    }
    
    taikinhandleChange(e){
      this.setState({temp_taikin:e.target.value});
    }
    
    
    //時計の為のメソッド
    componentDidMount() {
      this.timerID = setInterval(
        () => this.tick(),
        1000
      );
    }
    
    //時計の為のメソッド
    componentWillUnmount() {
      clearInterval(this.timerID);
      
      
      //ポップアップ
      document.removeEventListener('click',this.closeModal);
    }

    //時計の為のメソッド
    tick() {
      this.setState({
        date: new Date()
      });
    }
    
    //API GatewayのURL
    //入力欄の情報 → Dynamo DBへデータ追加
    // tempinput(event) {
    //   axios.get('https://r2ayyx80nl.execute-api.ap-northeast-1.amazonaws.com/dev/p-tempinput?SYAIN_ID='+ this.state.syain_id + '&DATE='+ this.state.year+ '-'+ this.state.month+ '-' +this.state.day + '&SYMPTOM1=' + this.state.symptom1 + '&SYMPTOM2=' + this.state.symptom2 +  '&TEMP_SYUKKIN=' + this.state.temp_syukkin + '&TEMP_TAIKIN='+this.state.temp_taikin);
    //     this.setState({isModalOpen:true});
    //     document.addEventListener('click',this.closeModal);
    //     event.stopPropagation();
    // }
    
    tempinput(event) {
      if (this.state.temp_syukkin && this.state.symptom1 && this.state.symptom2 !=''){
      axios.get('https://r2ayyx80nl.execute-api.ap-northeast-1.amazonaws.com/dev/p-tempinput?SYAIN_ID='+ this.state.syain_id + '&DATE='+ this.state.year+ '-'+ this.state.month+ '-' +this.state.day + '&SYMPTOM1=' + this.state.symptom1 + '&SYMPTOM2=' + this.state.symptom2 +  '&TEMP_SYUKKIN=' + this.state.temp_syukkin + '&TEMP_TAIKIN='+this.state.temp_taikin);
        this.setState({isModalOpen:true});
        document.addEventListener('click',this.closeModal);
        event.stopPropagation();
      }
    }    
    
    
    //Dynamo DBにある今日の入力済みデータ → 入力欄へ入力
    templist(){
      axios.get('https://zowk1vo7i3.execute-api.ap-northeast-1.amazonaws.com/dev/p-templist?SYAIN_ID=' + this.state.syain_id + '&DATE=' + this.state.year+ '-'+ this.state.month + '-' +this.state.day)
      .then (res => {
        const healthData = res.data.record;
        this.setState({healthList : healthData});
        this.setState({temp_syukkin : this.state.healthList[0].TEMP_SYUKKIN});
        this.setState({temp_taikin  : this.state.healthList[0].TEMP_TAIKIN});
        this.setState({symptom1     : this.state.healthList[0].SYMPTOM.symp1});
        this.setState({symptom2     : this.state.healthList[0].SYMPTOM.symp2});
      })
      .catch(() => {
        
      }); 
      
    }
  
  //コールバック関数
  //カレンダーで日付変更をするとこの関数が呼び出される
  datechange(year,month,day){
    document.tempinput.reset();//入力欄の値を一旦クリア
    this.setState({
      year : year,
      month : month,
      day : day,
      temp_syukkin:'',
      temp_taikin:'',
      symptom1:'',
      symptom2:''
    },() => {this.templist();}
    );
  }

    //ポップアップ
    closeModal(){
      this.setState({isModalOpen:false});
      document.removeEventListener('click',this.closeModal);
    }
    

    


    render() {

      return (
 
      <div className='screen'>
        <div className='contents'>
          
          {/*ヘッダー・サイドメニュー*/}
          <div>
            {/*親から子へparent_value変数を使用してstateの値を送る*/}
            <Header title='体温入力' parent_value={this.state.syain_id} />
          </div>
          
          <div>{/*カレンダーコンポーネント　コールバック関数で日付変更時に検索するようにしている*/}
            <KUC_Calendar year={this.state.year} month={this.state.month} day={this.state.day} callback={this.datechange} />
          </div>
          
          <p></p>
          <p></p>
           
           {/*入力フォーム*/}
           {/*<form name="tempinput" action="index.html" method="post">*/}
           {/*<form name="tempinput" action="">*/}
           
           {/*formタグには遷移先を指定する必要があるが、画面遷移はしたくないため、iflameタグを使用してformに入力された情報は送るが、画面遷移はしないように設定した*/}
           <form name="tempinput" action="index.html" method="post" target="f1">
            <dl>
              
              <dt>出勤前の体温</dt>
              <dd><input className={Style.input} type="number"  name="temp_syukkin"  defaultValue={this.state.temp_syukkin} onChange={this.syukkinhandleChange} required placeholder="℃"/></dd>
              
              
              <dt>帰宅後の体温</dt>
              <dd><input className={Style.input} type="number"  name="temp_taikin"  defaultValue={this.state.temp_taikin} onChange={this.taikinhandleChange} placeholder="℃"/></dd>
              
              <dt>息苦しさ　　</dt>
              <dd className={Style.symptom} >
                  <label><input id="radio1"  for="radio1" class="radiobutton" className={Style.radiobutton} name="symptom1" type="radio" checked={this.state.symptom1==="False"} onClick={() => {this.setState({symptom1:'False'})}} required/>無</label>
                  <label><input id="radio2"  for="radio2" class="radiobutton" className={Style.radiobutton} name="symptom1" type="radio" checked={this.state.symptom1==="True"} onClick={() => {this.setState({symptom1:'True'})}}/>有</label>
              </dd>
        
              <dt>強いだるさ　</dt>
              <dd className={Style.symptom}>
                  <label><input id="radio3" for="radio3" class="radiobutton" className={Style.radiobutton} name="symptom2" type="radio"  checked={this.state.symptom2==="False"} onClick={() => {this.setState({symptom2:'False'})}} required/>無</label>
                  <label><input id="radio4" for="radio4" class="radiobutton" className={Style.radiobutton} name="symptom2" type="radio"  checked={this.state.symptom2==="True"} onClick={() => {this.setState({symptom2:'True'})}}/>有</label>
              </dd>
              
            </dl>
            {/*<input type="submit" value="登録" variant="contained" className={Style.contentsItem} style={{marginBottom:50}} onClick={(event) => this.tempinput(event)}/>*/}
            
            {/*登録ボタン State内のデータをDynamoDBへ入力*/}
            <div className={Style.contentsItem} style={{marginBottom:50}}>
            <Button type="submit" className={Style.button} variant="contained" style={{fontSize:'100%'}} color="primary" onClick={(event) => this.tempinput(event)}>登録</Button>
            {this.state.isModalOpen? <Modal closeModal_send={()=>{this.closeModal()}}/> :""}
            </div>
            
           </form>
           {/*formタグには遷移先を指定する必要があるが、画面遷移はしたくないため、iflameタグを使用してformに入力された情報は送るが、画面遷移はしないように設定した*/}
           <iframe name="f1"  style={{width:0, height:0 ,visibility:"hidden"}}></iframe>
           
           {/*チャットボット表示
           <iframe src='https://d32trjq49e1qir.cloudfront.net/index.html' height='500' width='250'/>
           */}


           
          {/*登録ボタン State内のデータをDynamoDBへ入力*/}
          {/*
          <div className={Style.contentsItem} style={{marginBottom:50}}>
          <Button className={Style.button} variant="contained" style={{fontSize:'150%'}} color="primary" onClick={(event) => this.tempinput(event)}>登録</Button> 
          {this.state.isModalOpen? <Modal closeModal_send={()=>{this.closeModal()}}/> :""}
          </div>
          */}
          
        </div>
      </div>
      );
    }
}


export default App;