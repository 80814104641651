import React from 'react';
import Style from '../tempinput/stylesheet.module.css'; //CSSをオブジェクト化して、適用したい場所だけを指定している
import {withRouter} from 'react-router';//子コンポーネント(Header)から他の画面にstateを送るために必要
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';//子コンポーネント(Header)から他の画面にstateを送るために必要

// 子コンポーネント（モーダル）
class Modal extends React.Component {
  
  render(){
    return(
        <div id="modal" className={Style.modal} onClick={(event)=>{event.stopPropagation()}}>
          <div>
            <CheckCircleIcon fontSize="large"/>
            <p style={{fontSize:30,fontWeight:600}}>登録しました</p>
            {/*<Button className={Style.button} variant="contained" color="primary" onClick={this.props.closeModal_send}>閉じる</Button>*/}
          </div>
        </div>
    );
}}
export default withRouter(Modal);