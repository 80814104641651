import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import kimura_logo from "../icons/kimura_logo_white.png";
import menu_icon from "../icons/menu_icon_32.png";
import tempinput_icon from "../icons/tempinput_icon_32.png";
import templist_icon from "../icons/templist_icon_32.png";
import membertemp_icon from "../icons/membertemp_icon_32.png";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Style from '../tempinput/stylesheet.module.css'; //CSSをオブジェクト化して、適用したい場所だけを指定している
import {withRouter} from 'react-router';//子コンポーネント(Header)から他の画面にstateを送るために必要
import PropTypes from 'prop-types';//子コンポーネント(Header)から他の画面にstateを送るために必要
//import exportFunction from '../login/login';

const propTypes = {
  sendSignOut: PropTypes.func,
  
};

class Header extends React.Component {
  
  static propTypes={
    syain_id:PropTypes.object.isRequired
  }
  
  constructor(props) {
  super(props);
  
  //stateの中に入れた変数の値は共有される
  this.state = {
    syain_id: this.props.parent_value,//親(tempinput.js)から社員IDを受け取る
    open:false
    
    
  };
  this.handleClick = this.handleClick.bind(this);
  this.handleClick2 = this.handleClick2.bind(this);
  this.handleClick3 = this.handleClick3.bind(this);
  this.handleClick4 = this.handleClick4.bind(this);
  this.handleToggle = this.handleToggle.bind(this);
  
  }
  
  //サイドメニュー
  handleToggle() {
      console.log('Handle Toggle');
      this.setState({open: !this.state.open});
  }

  //体温一覧画面にパラメータを渡す
  handleClick(){
  this.props.history.push({
    pathname: "/templist",
    state: {
            syain_id: this.props.parent_value,
            searchID: this.props.parent_value
            //year : this.state.year,
            //month : this.state.month
           }
  });
  } 
  //ログイン画面とパラメータのやり取り
  handleClick2(){
  this.props.history.push({
    pathname: "/",
    state: {
            syain_id: this.props.parent_value//this.state.syain_id,

           }
  });
  }
  
  //体温管理画面とパラメータのやり取り
  handleClick3(){
  this.props.history.push({
    pathname: "/membertemp",
    state: {
            syain_id: this.props.parent_value//this.state.syain_id,

           }
  });
  }

  //体温入力画面へ遷移
  handleClick4(){
  this.props.history.push({
    pathname: "/tempinput",
    state: {
            syain_id: this.props.parent_value,//this.state.syain_id,
            year:new Date().getFullYear(),
            month:('0'+(new Date().getMonth()+1)).slice(-2),
            day:('0'+new Date().getDate()).slice(-2)
           }
  });
  }

  // 高瀬記述 start
  // サインアウト処理
  signOut(){
      //await Auth.signOut();
      // Auth.signOut().then(result => {
      //   console.log(result);
      // });
  }// 高瀬記述 end

  clickSignOutButton() {
    return this.props.sendSignOut();
  }

  render() {
    return (
    
    <div className={Style.header}>
     <AppBar className={Style.AppBar} position="static" style={{ color: "primary"}}>
      <Toolbar>
      
        {/*サイドメニュー*/}            
        <IconButton color="inherit" onClick={this.handleToggle}>
         <MenuIcon/>
        </IconButton>

        <Drawer variant="temporary" open={this.state.open} onClose={this.handleToggle} docked={false} width={200} onRequestChange={this.handleToggle}>
          <List>
          <a href="/" onClick={this.handleClick2}>
            <ListItem>
              <ListItemIcon>
                <img src={menu_icon} alt="menu" />
              </ListItemIcon>
              <ListItemText primary="メニュー"/>
            </ListItem>
          </a>

          <a href="/tempinput" onClick={this.handleClick4}>
            <ListItem>
              <ListItemIcon>
                <img src={tempinput_icon} alt="tempinput" />
              </ListItemIcon>
              <ListItemText primary="体温入力" />
            </ListItem>
          </a>

          
          <a href="/templist" onClick={this.handleClick}>
            <ListItem>
              <ListItemIcon>
                <img src={templist_icon} alt="templist" />
              </ListItemIcon>
              <ListItemText primary="体温一覧" />
            </ListItem>
          </a>
          
          <a href="/membertemp" onClick={this.handleClick3}>
            <ListItem>
              <ListItemIcon>
                <img src={membertemp_icon} alt="membertemp"/>
              </ListItemIcon>
              <ListItemText primary="体温管理"/>{/*secondary="管理者用"*/}
            </ListItem>
          </a>
          
          
        {/* 高瀬記述 start 
         <a href="/" onClick={() => {
            Auth.signOut({
              global: true
            }).then(() => {
              push('/')
              dispatchAuthStateChangeEvent(AuthState.SignedOut)
            })
          }}>
           <ListItem>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#dc143c" }} fontSize="large"/>
              </ListItemIcon>
              <ListItemText primary="サインアウト"/>
            </ListItem>
          </a>
          高瀬記述 end*/}

         {
        // 高瀬記述 start 2021/12/09最新
        // <a href="/" onClick={() =>Auth.signOut}>
        //   <ListItem>
        //       <ListItemIcon>
        //         <ExitToAppIcon style={{ color: "#dc143c" }} fontSize="large"/>
        //       </ListItemIcon>
        //       <ListItemText primary="サインアウト"/>
        //     </ListItem>
        //   </a>
        //   高瀬記述 end
          
         }


        
        {  
        // 安達記述 最新
        // <a href="/" onClick={() => {this.clickSignOutButton();}}>
        
        //   <ListItem>
        //       <ListItemIcon>
        //         <ExitToAppIcon style={{ color: "#dc143c" }} fontSize="large" />
        //       </ListItemIcon>
        
        //       <ListItemText primary="サインアウト"/>
        //     </ListItem>
        // </a>
        }


         {/* <Link onClick={exportFunction.signOut}>
            <ListItem>
              <ListItemIcon>
                <ExitToAppIcon/>
              </ListItemIcon>
              <ListItemText primary="サインアウト"/>
            </ListItem>
          </Link>
         */}
         
            {/*
            <ListItem>
              <ListItemIcon>
                <ExitToAppIcon/>
              </ListItemIcon>
              <ListItemText primary="サインアウト"/>
            </ListItem>
            */}
            
          </List>
        </Drawer>
        
        
        {/*<Button variant="contained" style={{position:'absolute'}} color="default" href={'/'} onClick={this.handleClick2} >メニュー</Button>*/}
        
        <Typography variant="h6" className={Style.title}> {/*style={{position:"relative",zIndex:20,width:"1000px",margin:"auto"}}*/}
         {this.props.title}{/*体温入力*/}
        </Typography>
        
        {/*社員番号*/}
        
        
        
        
        <Typography className={Style.syain_id} >{/*style={{position:"absolute",right:"50px",fontWeight:"600"}}*/}
          <img className={Style.kimura_logo} src={kimura_logo} width="30" height="30"/>
          &nbsp;&nbsp;社員番号&nbsp;&nbsp;&nbsp;{this.props.parent_value}{/*this.state.syain_id*/}
          
        </Typography>
        
        
      </Toolbar>
     </AppBar>
    </div>    
    );
  }


}
export default withRouter(Header);