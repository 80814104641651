import React, { Component } from 'react';
import axios from 'axios';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Header from '../common/Header';//ヘッダー部を読み込み
import './tableStyle.css';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
        healthList: [],
        ID : this.props.location.state.syain_id,  //メニュー画面から受け取ったパラメータ値を利用する
        searchID : this.props.location.state.searchID, //検索に使用するID
        NAME : "",
        YEAR : new Date().getFullYear(),
        MONTH : ('0'+(new Date().getMonth()+1)).slice(-2),
    };
    this.moveEdit = this.moveEdit.bind(this);
    
  }
  
  componentDidMount() { //画面起動時に検索処理実行
    this.getHealthList();
  }
  
  getHealthList(){
      const url = 'https://zowk1vo7i3.execute-api.ap-northeast-1.amazonaws.com/dev/p-templist?SYAIN_ID='
                    + this.state.searchID + '&DATE=' + this.state.YEAR + '-' + ('0'+ this.state.MONTH).slice(-2);
      axios.get(url) //APIGateWay呼び出し
      .then (res => {
        const healthData = res.data.record;
        const info = res.data
        this.setState({healthList : healthData});
        this.setState({NAME : info["NAME"]});
      })
      .catch(() => {
        alert('通信に失敗しました');
      }); 
  }
  
  symp_check(symp) {
    if(symp === "True"){
      return '有';
    }
    else {
      return '無';
    }
  }
  
  fix(date) {
    
    if(this.state.ID == this.state.searchID) {
      const splitdate = date.split('-'); //yyyy-mm-ddを分割
      const year = splitdate[0];
      const month = splitdate[1];
      const day = splitdate[2];
      return (
        <div>
          <button className='tbody linkstyle_btn' onClick={()=>{this.moveEdit(year,month,day)}}>
            {date}
          </button>
        </div>
      );
    }
    else{
      return(
        <div>
          {date}
        </div>
      )
    }
	}
	
	moveEdit(year,month,day) {
	  this.props.history.push({
    pathname: "/tempinput",
    state: {
            syain_id: this.state.ID,
            year: year,
            month: month,
            day:day
           }
  });
	}
	
	
	handleChangeYEAR(event) {
	  this.setState({YEAR: event.target.value});
	}
	
	handleChangeMONTH(event) {
	  this.setState({MONTH: event.target.value});
	}
	
	render () {

    
    return(
      <div>
        <div>
          <Header title='体温一覧' parent_value={this.state.ID}/>
        </div>
      
        <div id="searchArea">
          <span className="syain_name">{this.state.NAME}</span>
          <br></br>
          <label className="year"> 
            <input type="text"  value={this.state.YEAR} onChange={(event) => {this.handleChangeYEAR(event)}} /> 年
          </label>
          <label className="month" >
            <input type="text"  value={this.state.MONTH} onChange={(event) => {this.handleChangeMONTH(event)}} /> 月
          </label>
          <Button onClick = {() => {this.getHealthList()}} variant="contained"  color="primary" style={{fontWeight:"bold"}}>検索</Button>
        </div>
          <TableContainer component={Paper}>
            <Table className="table" aria-label="simple table">
              <TableHead id="tableheader">
                <TableRow>
                  <TableCell className="thead lcell">日付</TableCell>
                  <TableCell className="thead">出勤前</TableCell>
                  <TableCell className="thead">帰宅後</TableCell>
                  <TableCell className="thead">息苦しさ</TableCell>
                  <TableCell className="thead">強いだるさ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="tablebody">
                {this.state.healthList.map((healthItem) => (
                  <TableRow key={healthItem.DATE}>
                    <TableCell className="tbody lcell" component="th">
                      {this.fix(healthItem.DATE)}
                    </TableCell>
                    <TableCell className="tbody">{healthItem.TEMP_SYUKKIN}</TableCell>
                    <TableCell className="tbody">{healthItem.TEMP_TAIKIN}</TableCell>
                    <TableCell className="tbody">{this.symp_check(healthItem.SYMPTOM.symp1)}</TableCell>
                    <TableCell className="tbody">{this.symp_check(healthItem.SYMPTOM.symp2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
	}
}    
            


export default Main;