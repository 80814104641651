import "../../App.css";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from "../../aws-exports";
import { useHistory } from 'react-router-dom';
import React, {useEffect} from "react";//Reactを読み込んでいる
import { CognitoUserPool, CognitoUserAttribute, CognitoUser} from 'amazon-cognito-identity-js';
import Button from '@material-ui/core/Button'; // Material-uiのボタンを読み込んでいる
import * as AWS from 'aws-sdk/global';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
// ES Modules, e.g. transpiling with Babel
import './loginstyle.css';
import tempinput_icon from "../icons/tempinput_icon_64.png";
import templist_icon from "../icons/templist_icon_64.png";
import membertemp_icon from "../icons/membertemp_icon_64.png";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Header from '../common/Header';//ヘッダー部を読み込み
/* takase add start */
import {
    //AuthState,
    UI_AUTH_CHANNEL,
    AUTH_STATE_CHANGE_EVENT,
    AuthStateHandler,
  } from '@aws-amplify/ui-components';
  import { Hub } from 'aws-amplify';
/* takase add end */

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
Amplify.configure(awsconfig);




// AmplifyAuthenticator内にAmplifySignInを追加し
// 以下参考に文言を渡していく
function App() {
  const [syain_userid, setSyain_userid] = React.useState("");
  const history = useHistory();    // history.push()

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
    setAuthState(nextAuthState);
    setUser(authData);

    init();
    });
  });
  
    const init = async() => {
    // // ユーザープールの設定(aws-exports.jsから参照)
    const poolData = {
      UserPoolId:"ap-northeast-1_227YL4Jmb",
      ClientId:"7kblqm34dq0cgr40pha0lnifs7"
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser(); // 現在のユーザ
    const currentUserData = {}; // ユーザーの属性情報
  
    // Amazon Cognito 認証情報プロバイダーを初期化します
    AWS.config.region = "ap-northeast-1"; // リージョン
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "ap-northeast-1:3bca6e4c-efe7-4094-a3d5-bd2bcc9dbcaa"
    });

    // 現在のユーザーの属性情報を取得・表示する
    // 現在のユーザー情報が取得できているか？
    if (cognitoUser != null) {

      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else {
          // ユーザの属性を取得
          cognitoUser.getUserAttributes((err, result) => {
            if(err){
              console.log(err);
            }
            // 取得した属性情報を連想配列に格納
            for (let i = 0; i < result.length; i++) {
              currentUserData[result[i].getName()] = result[i].getValue();
              setSyain_userid(currentUserData["name"]);
            }
          });
        }
      });
    }
  };

    // サインアウト
    const signOut = async() => {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
    document.location.reload();
    };

    // 体温入力画面遷移
    const gotoinput = () =>{
      init();
      history.push({
          pathname:  "/tempinput",
          state: {
              syain_id: syain_userid,
              year:new Date().getFullYear(),
              month:('0'+(new Date().getMonth()+1)).slice(-2),
              day:('0'+new Date().getDate()).slice(-2)
          }
      });
    };

    // 体温一覧画面遷移
    const gotolist = () => {
      init();
      history.push({
          pathname:  "/templist",
          state: {
              syain_id: syain_userid,
              searchID: syain_userid
          }
      });
    };
    
    // 体温管理画面遷移
    const gotogroup = () => {
        history.push({
            pathname:  "/membertemp",
            state: {
              syain_id: syain_userid
            }
        });
    };
    
    //九九の画面へ遷移
    const gotokuku = () => {
      window.location.href = 'https://d32trjq49e1qir.cloudfront.net/index.html';
    };


  return authState === AuthState.SignedIn && user ? (
    
      <div className="contents">
        {/*ヘッダー・サイドメニュー*/}
        <div>
          <Header title='メニュー' parent_value={syain_userid}
            sendSignOut={() => {signOut()}}
          />
        </div>

      <div className="flex_box">
      <p></p>
      <p></p>
      <p></p>
        
        <div className="flex_item">
            
            <Button className="Button" variant="contained"  color="primary" onClick={gotoinput}>
              <img className="icon" src={tempinput_icon} alt="tempinput"/>
              体温入力
            </Button> 
        </div>
        <div className="flex_item">
            <Button className="Button" variant="contained"  color="primary" onClick={gotolist}>
              <img className="icon" src={templist_icon} alt="templist"/>
              体温一覧
            </Button>
        </div>
        <div className="flex_item">
            <Button className="Button" variant="contained"  color="primary" onClick={gotogroup}>
              <img className="icon" src={membertemp_icon} alt="membertemp"/>
              体温管理{/*係メンバー<br/>体温一覧*/}
            </Button>
        </div>
        <div className="flex_item">{/*style={{fontSize:"150%"}}*/}
            <Button className="Button" variant="contained"  color="secondary" onClick={signOut}>
              <ExitToAppIcon className="exiticon"/>{/*style={{fontSize:64}}*/}
            サインアウト
            </Button>
        </div>
        {/*<div className="flex_item">
            <Button className="Button" variant="contained"  color="primary" onClick={gotokuku}>*/}
              {/*<img className="icon" src={membertemp_icon} alt="membertemp"/>*/}
              {/*九九ボット
            </Button>
        </div>*/}
        


        {/*
        <div className="flex_item">
          <Button className="signout_Button" variant="contained"  color="secondary" style={{fontWeight:"bold"}} onClick={signOut}>サインアウト</Button>
        </div>
        */}
      </div>
      {  
      // <Button className="signout_Button" variant="contained"  color="secondary" style={{fontWeight:"bold"}} onClick={signOut}>サインアウト</Button>
      }
    </div>

  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn
        slot="sign-in"
        headerText="サインイン画面"
        submitButtonText="サインイン"
        //サインアップリンク非表示
        hideSignUp={true}
        
        formFields={[
          {
            type: "username",
            label: "Email *",
            placeholder: "Emailを入力",
            required: true,
          },
          {
            type: "password",
            label: "パスワード *",
            placeholder: "パスワードを入力",
            required: true,
          },
        ]}
      />
      {/*<AmplifySignUp
        slot="sign-up"
        headerText="サインアップ"
        haveAccountText=""
        signInText="サインインに戻る"
        submitButtonText="アカウント作成"

        // formFields内に必要な項目だけを指定
        formFields={[
          {
            type: "username",
            label: "ユーザ名(メールアドレス)を入力してください",
            placeholder: "ユーザ名(メールアドレス)",
          },
          {
            type: "name",
            label: "社員NOを入力してください",
            placeholder: "社員NO",
          },
          {
            type: "email",
            label: "メールアドレスを入力してください",
            placeholder: "メールアドレス",
          },
          {
            type: "password",
            label: "パスワードを入力してください",
            placeholder: "パスワード",
            inputProps: { required: true, autocomplete: "new-password" },
          },
        ]}
      />*/}
    </AmplifyAuthenticator>
  );
}

export default App;
// export const dispatchAuthStateChangeEvent: AuthStateHandler = (
//     nextAuthState: AuthState,
//     data?: object
//   ) => {
//     Hub.dispatch(UI_AUTH_CHANNEL, {
//       event: AUTH_STATE_CHANGE_EVENT,
//       message: nextAuthState,
//       data,
//     });
//   };